<template>
  <div class="h-100 w-75">
    <div v-if="lang === 'en'">
      <p><b>
        Privacy Policy
      </b></p>

      <br/>
      This application “eMoty” (hereinafter referred to as the “App”) is the property of “eMoters” LTD (ID/N 405420030) (hereinafter referred to as “We”/”Us”/”Our”). This Privacy Policy states how we collect, use and process the personal data of our App users.
      We use the personal data you have provided to improve our service and App. By using the App, you consent to our processing of your personal data in the manner prescribed by law, for the purposes specified in this Privacy Policy.
      <br/>
      <br/>
      Topics:
      <br/>
      <br/>
      <ul>
        <li>
          What data do we collect?
        </li>
        <li>
          How do we collect your data?
        </li>
        <li>
          How do we use your data?
        </li>
        <li>
          How do we store your data?
        </li>
        <li>
          Marketing
        </li>
        <li>
          What are your rights regarding the personal data protection?
        </li>
        <li>
          What are cookies?
        </li>
        <li>
          How do we use cookies?
        </li>
        <li>
          What types of cookies do we use?
        </li>
        <li>
          How you can manage cookies
        </li>
        <li>
          Privacy policies of other websites
        </li>
        <li>
          Modifications to our Privacy Policy
        </li>
        <li>
          How to contact us
        </li>
        <li>
          How to contact the authorized bodies
        </li>
      </ul>

      <b>What data do we collect?</b>
      <br/>
      Our company collects the data that includes, but is not limited to:
      <ul>
        <li>
          Personal identification data (given name, surname, e-mail, address, phone number, etc.)
        </li>
        <li>
          Your IP address;
        </li>
        <li>
          Time of visit;
        </li>
        <li>
          Date and duration of visit
        </li>
      </ul>
      <br/>
      <br/>
      <b>How do we collect your data?</b>
      <br/>
      Most of the information we collect is provided directly by you. We collect and process data when you:
      <ul>
        <li>
          register in our App or order a product.
        </li>
        <li>
          voluntarily complete a user survey or send us feedback using e-mail or other means.
        </li>
        <li>
          visit our App by means of cookies;
        </li>
        <li>
          use our service by phone.
        </li>
        <li>
          otherwise use our App and/or the services we offer.
        </li>
      </ul>
      <br/>
      <br/>
      The above list is not complete and personal data may be obtained from other indirect sources at the time of your interaction with us.
      <br/>
      <br/>
      <b>How do we use your data?</b>
      <br/>
      Our company collects your data in order for us to be able to:
      <ul>
        <li>
          process your order, manage your account.
        </li>
        <li>
          send you special offers about our services and products by e-mail
        </li>
        <li>
          for other cases determined by this Policy;
        </li>
      </ul>
      <br/>
      <br/>
      If you consent, we may share your data with partner companies in order for them to be able to offer you their own services and products.
      <br/>
      <br/>
      <b>
        How do we store your data?
      </b>
      <br/>
      We implement all reasonable technical and organizational security measures available to us to protect your personal data from unauthorized access, misuse, loss or destruction.
      <br/>
      <br/>
      Our company will store your personal data in the manner prescribed by the applicable laws, for the period necessary to attain the purposes determined by us (advertising purposes, service improvement, etc.). We will delete/destroy your personal data after the purposes determined by our company in this Policy no longer exist.

      <b>What are your rights regarding the personal data protection?</b>
      <br/>
      Our company wants you to be sure that we process and store your personal data in compliance with the maximum safety rules. We also protect and respect your rights regarding the personal data protection. Any user of the App has the right to:

      <ul>
        <li>
          Right to request access - you have the right to request copies of your personal data held by our company.
        </li>
        <li>
          Right to request correction - you have the right to request from our company to correct any information that you consider incorrect or inaccurate. In addition, you have the right to request from our company to fill in the information that you consider incomplete.
        </li>
        <li>
          Right to request deletion - you have the right to request from our company to delete your personal data, subject to certain conditions.
        </li>
        <li>
          Right to request restriction of processing - you have the right to request from our company to restrict your personal data processing, subject to certain conditions.
        </li>
        <li>
          Right to request transfer of data - you have the right to request that the data collected by our company be transferred to other organization, or directly to you, subject to certain conditions.
        </li>
      </ul>

      If requested, we are obliged to provide you with an answer within 10 days.
      <br/>
      <br/>

      <b>What are cookies?</b>
      <br/>
      Cookies are small text files, stored in your computer, tablet or mobile phone. When you log into the App, cookies allow the App to remember you and remember how you used the App at the time of each login, thereby ensuring that the App is better tailored to you. Thereafter, when you log into our App, we may also collect information through automated means using cookies or similar technologies.

      <br/>
      <br/>
      For more information, visit <a href="https://allaboutcookies.org/">allaboutcookies.org.</a>
      <br/>
      <br/>
      <b>How do we use cookies?</b>
      <br/>
      Our company uses cookies to:
      <ul>
        <li>
          track the flow of visitors to the App and the peculiarities of their traffic;
        </li>
        <li>
          monitor the effectiveness of the App and constantly improve it;
        </li>
        <li>
          tailor the App to users and enhance their experience;
        </li>
        <li>
          otherwise improve the service we provide.
        </li>
      </ul>

      <b>What types of cookies do we use?</b>
      <br/>
      In general, there are many types of cookies, but we use the following:
      <ul>
        <li>
          <i>Functionality</i> -  Functionality cookies record information about your preferences, thereby allowing us to tailor the App to your interests. The essence of these cookies is that when you continue to use or return back to the App, we can provide you with services in the way that you require. For example, these cookies allow us to remember the language you use, the location you have logged from, frequently viewed products, etc.
        </li>
        <li>
          <i>Advertising</i> - Our company uses such cookies about your visits in our App, the content you have viewed, the links you have followed, and information about your device and IP address. Sometimes, our company shares this information with third parties for advertising purposes.
        </li>
      </ul>
      <b>How you can manage cookies</b>
      <br/>
      You can set the App so that it will not use cookies, and the given App will show you how to delete cookies. However, please note that in such a case, you may not be able to completely use certain functions of our App.
      <br/>
      <br/>
      <b>Privacy policy of other websites</b>
      <br/>
      Our App may contain links to other websites. Our Privacy Policy only applies to our App, so if you click on a link to other website, be sure to read their privacy policy.
      <br/>
      <br/>
      <b>Modifications to our Privacy Policy</b>
      <br/>
      Our company will constantly adapt the Privacy Policy to the applicable laws and principles, and relevant updates will be shown in the App.
      <br/>
      <br/>

      <b>How to contact us</b>
      <br/>
      If you have any queries about our company’s Privacy Policy, the information we collect, or simply wish to exercise your rights regarding personal data, please contact us at:
      <br/>
      <br/>
      Address : N89 Avto Varazi Str., Vake district, Tbilisi, Georgia
      E-mail: levaninad@gmail.com

      <b>How to contact the authorized bodies</b>
      State Inspector’s Service is an independent state authority, whose purpose is to control the legality of personal data processing. Thereafter, if you believe that your rights have been violated at the time of processing your personal data, contact the State Inspector’s Service:
      <br/>
      Phone: (+995 32) 242 1000
      <br/>
      E-mail: <a href="office@stateinspector.ge">office@stateinspector.ge</a>
      <br/>
      Address: N°7 N. Vachnadze Str., 0105 Tbilisi, Georgia -  Office of personal data protection;

    </div>
    <div v-else>
      <p>
        <b>
          კონფიდენციალურობის პოლიტიკა
        </b>
      </p>
      <br/>
      წინამდებარე აპლიკაცია „eMoty” (შემდგომში „აპლიკაცია“) წარმოადგენს შპს „ემოუთერს“-ის (ს/ნ 405420030) (შემდგომში „ჩვენ“) საკუთრებას. ეს აპლიკაცია გამცნობთ, თუ როგორ  ვაგროვებთ, ვიყენებთ და ვამუშავებთ ჩვენი აპლიკაციის მომხმარებლების პერსონალურ მონაცემებს.
      <br/>
      <br/>
      ჩვენ თქვენს მიერ მოწოდებულ პერსონალურ მონაცემებს ვიყენებთ ჩვენი სერვისისა და აპლიკაციის გასაუმჯობესებლად. აპლიკაციის გამოყენებით, თქვენ ეთანხმებით ჩვენს მიერ თქვენი პერსონალური მონაცემების დამუშავებას კანონით გათვალისწინებული წესით, წინამდებარე კონფიდენციალურობის პოლიტიკით განსაზღვრული მიზნებისათვის.

      <br/>
      <br/>
      თემები:
      <br/>
      <br/>
      <ul>
        <li>
          რა მონაცემებს ვაგროვებთ ჩვენ?
        </li>
        <li>
          როგორ ვაგროვებთ თქვენს მონაცემებს?
        </li>
        <li>
          როგორ გამოვიყენებთ თქვენს მონაცემებს?
        </li>
        <li>
          როგორ ვინახავთ თქვენს მონაცემებს?
        </li>
        <li>
          მარკეტინგი
        </li>
        <li>
          რა უფლებები გაქვთ პერსონალური მონაცემების დაცვასთან დაკავშირებით?
        </li>
        <li>
          რა არის „ქუქი“ ფაილები (cookies)?
        </li>
        <li>
          როგორ ვიყენებთ „ქუქი“ ფაილებს?
        </li>
        <li>
          რა ტიპის „ქუქი“ ფაილებს ვიყენებთ ჩვენ?
        </li>
        <li>
          როგორ შეგიძლიათ მართოთ „ქუქი“ ფაილები
        </li>
        <li>
          სხვა ვებ გვერდების კონფიდენციალურობის პოლიტიკა
        </li>
        <li>
          ცვლილებები ჩვენს კონფიდენციალურობის პოლიტიკაში
        </li>
        <li>
          როგორ შეგვეხმიანოთ ჩვენ?
        </li>
        <li>
          როგორ შეეხმიანოთ უფლებამოსილ ორგანოებს?
        </li>
      </ul>

      <b>რა მონაცემებს ვაგროვებთ ჩვენ?</b>
      <br/>
      ჩვენი კომპანია აგროვებს მონაცემებს, რომლებიც მოიცავს, თუმცა არ შემოიფარგლება:
      <ul>
        <li>
          პირადი საიდენტიფიკაციო მონაცემები (სახელი, გვარი, ელ. ფოსტა, მისამართი, ტელეფონის ნომერი და ა.შ.)
        </li>
        <li>
          თქვენი IP მისამართი;
        </li>
        <li>
          ვიზიტის დრო;
        </li>
        <li>
          სტუმრობის თარიღი და ხანგრძლივობა
        </li>
      </ul>

      <b>როგორ ვაგროვებთ თქვენს მონაცემებს?</b>
      <br/>
      ჩვენს მიერ შეგროვებული ინფორმაციის უდიდეს ნაწილს თქვენ პირდაპირ გვაწვდით. ჩვენ ვაგროვებთ და ვამუშავებთ მონაცემებს, როდესაც თქვენ:
      <ul>
        <li>
          რეგისტრირდებით ჩვენს აპლიკაციაში ან უკვეთავთ პროდუქტს.
        </li>
        <li>
          ნებაყოფლობით ავსებთ მომხმარებლის კვლევას ან გვიგზავნით უკუკავშირს (feedback) ელექტრონული ფოსტის ან სხვა საშუალებების გამოყენებით.
        </li>
        <li>
          სტუმრობთ ჩვენს აპლიკაციას „ქუქი“ ფაილების საშუალებით;
        </li>
        <li>
          იყენებთ ჩვენს სატელეფონო მომსახურების სერვისს.
        </li>
        <li>
          სხვაგვარად იყენებთ ჩვენს აპლიკაციას ან/და ჩვენს მიერ შემოთავაზებულ სერვისებს.
        </li>
      </ul>

      <br/>
      <br/>
      ზემოთ მოცემული ჩამონათვალი არ არის ამომწურავი და შესაძლოა ჩვენთან ურთიერთობის დროს შესაძლოა სხვა არაპირდაპირი წყაროებიდანაც მოხდეს პერსონალური მონაცემების მოპოვება.
      <br/>
      <br/>

      <b>როგორ გამოვიყენებთ თქვენს მონაცემებს?</b>
      <br/>
      ჩვენი კომპანია აგროვებს თქვენს მონაცემებს, რათა ჩვენ შევძლოთ:
      <ul>
        <li>
          თქვენი შეკვეთის დამუშავება, თქვენი ანგარიშის მართვა.
        </li>
        <li>
          თქვენთვის ელ-ფოსტით სპეციალური შემოთავაზებების გამოგზავნა ჩვენი სერვისებისა და პროდუქტების შესახებ.
        </li>
        <li>
          წინამდებარე პოლიტიკით განსაზღვრული სხვა შემთხვევებისთვის;
        </li>
      </ul>
      <br/>
      <br/>

      თქვენი თანხმობის შემთხვევაში, ჩვენ შეგვიძლია გავუზიაროთ თქვენი მონაცემები პარტნიორ კომპანიებს, რათა მათაც შეძლონ თქვენთვის საკუთარი სერვისებისა და პროდუქტების შემოთავაზება.
      <br/>
      <br/>

      <b>როგორ ვინახავთ თქვენს მონაცემებს?</b>
      <br/>
      ჩვენ ვახდენთ ჩვენთვის ხელმისაწვდომი ყველა გონივრული ტექნიკური და ორგანიზაციული უსაფრთხოების ზომების იმპლემენტაციას, რათა ჩვენს მიერ  მოპოვებული თქვენი პერსონალური მონაცემები დაცული იყოს უნებართვო წვდომისგან, გამოყენებისგან, დაკარგვისგან ან განადგურებისგან.
      <br/>
      <br/>

      ჩვენი კომპანია შეინახავს თქვენს პერსონალურ მონაცემებს მოქმედი კანონმდებლობით განსაზღვრული წესით, იმ პერიოდის განმავლობაში, რაც საჭიროა ჩვენს მიერ განსაზღვრული მიზნების (სარეკლამო მიზნები, მომსახურების გაუმჯობესება და ა.შ.) განსახორციელებლად. მას შემდეგ, რაც აღარ იარსებებს ჩვენი კომპანიის მიერ წინამდებარე პოლიტიკით განსაზღვრული მიზნები, ჩვენ წავშლით/გავანადგურებთ თქვენს პერსონალურ მონაცემებს.
      <br/>
      <br/>

      <b>რა უფლებები გაქვთ პერსონალური მონაცემების დაცვასთან დაკავშირებით?</b>
      <br/>
      ჩვენს კომპანიას სურს დარწმუნდეთ, რომ ჩვენ უსაფრთხოების მაქსიმალური წესების დაცვით ვამუშავებთ და ვინახავთ თქვენს პერსონალურ მონაცემებს. ჩვენ ასევე ვიცავთ და პატივს ვცემთ თქვენს უფლებებს პერსონალურ მონაცემების დაცვასთან დაკავშირებით. აპლიკაციის ნებისმიერ მომხმარებელს აქვს უფლება:

      <ul>
        <li>
          <i>წვდომის მოთხოვნის უფლება</i> - თქვენ გაქვთ უფლება, მოითხოვოთ ჩვენს კომპანიაში დაცული, თქვენი პერსონალური მონაცემების ასლები.
        </li>
        <li>
          <i>ცვლილების მოთხოვნის უფლება</i> - თქვენ გაქვთ უფლება, მოითხოვოთ ჩვენი კომპანიისგან ნებისმიერი ინფორმაციის შესწორება, რომელიც არასწორი ან არაზუსტი მიგაჩნიათ. ამასთანავე, თქვენ გაქვთ უფლება, მოითხოვოთ ჩვენი კომპანიისგან იმ ინფორმაციის შევსება, რომელიც არასრულყოფილად მიგაჩნიათ.
        </li>
        <li>
          <i>წაშლის მოთხოვნის უფლება</i> - თქვენ გაქვთ უფლება, მოითხოვოთ ჩვენი კომპანიისგან, თქვენი პერსონალური მონაცემების წაშლა, გარკვეული პირობების გათვალისწინებით.
        </li>
        <li>
          <i>დამუშავების შეზღუდვის მოთხოვნის უფლება</i> - თქვენ გაქვთ უფლება, მოითხოვოთ ჩვენი კომპანიისგან თქვენი პერსონალური მონაცემების დამუშავების შეზღუდვა, გარკვეული პირობების გათვალისწინებით.
        </li>
        <li>
          <i>მონაცემების გადაცემის მოთხოვნის უფლება</i> - თქვენ გაქვთ უფლება, მოითხოვოთ ჩვენი კომპანიის მიერ შეგროვებული მონაცემების სხვა ორგანიზაციისთვის, ან პირდაპირ თქვენთვის გადაცემა, გარკვეული პირობების გათვალისწინებით.
        </li>
      </ul>
      <br/>
      <br/>

      მოთხოვნის შემთხვევაში, ჩვენ ვალდებული ვართ გიპასუხოთ 10 დღის განმავლობაში.
      <br/>
      <br/>
      <b>რა არის „ქუქი“ ფაილები (cookies)?</b>
      <br/>
      ქუქი-ფაილები არის მცირე ზომის ტექსტური ფაილები, რომლებიც ინახება თქვენს კომპიუტერში, პლანშეტსა თუ მობილურ ტელეფონში. როდესაც შედიხართ აპლიკაციაში, ქუქი-ფაილები საშუალებას აძლევს აპლიკაციას, რომ დაგიმახსოვროთ თქვენ და დაიმახსოვროს, თუ როგორ გამოიყენეთ აპლიკაცია თითოეული შესვლის დროს, რაც უზრუნველყოფს აპლიკაციის უკეთესად მორგებას თქვენზე. შესაბამისად, როდესაც თქვენ შემოდიხართ ჩვენს აპლიკაციაში, ჩვენ შესაძლოა ასევე ავტომატური საშუალებებით მოვიპოვოთ ინფორმაცია „ქუქი“ ფაილების ან მსგავსი ტექნოლოგიების გამოყენებით.
      <br/>
      <br/>

      დამატებითი ინფორმაციისთვის, ეწვიეთ <a href="https://allaboutcookies.org/">allaboutcookies.org.</a>
      <br/>
      <br/>

      <b>როგორ ვიყენებთ „ქუქი“ ფაილებს?</b>
      <br/>
      ჩვენი კომპანია იყენებს „ქუქი“ ფაილებს, რათა:
      <ul>
        <li>
          თვალი ვადევნოთ აპლიკაციაში ვიზიტორთა ნაკადს და მათი გადაადგილების თავისებურებებს;
        </li>
        <li>
          ვაწარმოოთ აპლიკაციის ეფექტურობის მონიტორინგი და მუდმივად გავაუმჯობესოთ იგი;
        </li>
        <li>
          უზრუნველვყოთ აპლიკაციის მომხმარებლებზე მორგება და გავაძლიეროთ მათი გამოცდილება;
        </li>
        <li>
          სხვაგვარად გავაუმჯობესოთ ჩვენს მიერ მოწოდებული სერვისი.
        </li>
      </ul>

      <b>რა ტიპის „ქუქი“ ფაილებს ვიყენებთ ჩვენ?</b>
      <br/>
      ზოგადად, უამრავი სახის „ქუქი“ ფაილი არსებობს, თუმცა ჩვენ ვიყენებთ შემდეგ ფაილებს:
      <ul>
        <li>
          <i>ფუნქციონალობის</i> -  ფუნქციონალობის ქუქი-ფაილები იწერენ ინფორმაციას თქვენი პრიორიტეტების შესახებ, რაც საშუალებას გვაძლევს, რომ აპლიკაცია მოვარგოთ თქვენს ინტერესებს. ამ ქუქი-ფაილების არსი იმაში მდგომარეობს, რომ როდესაც თქვენ აგრძელებთ გამოყენებას ან უკან ბრუნდებით აპლიკაციაში, ჩვენ შეგვიძლია უზრუნველვყოთ სერვისები თქვენთვის ისეთი სახით, როგორსაც თქვენ მოითხოვთ. მაგალითად, ეს ქუქი-ფაილები საშუალებას გვაძლევს, რომ დავიმახსოვროთ თქვენს მიერ გამოყენებული ენა, ადგილმდებარეობა, რომლიდანაც შემოხვედით, ხშირად ნანახი პროდუქცია და ა.შ.
        </li>
        <li>
          <i>სარეკლამო</i> - ჩვენი კომპანია იყენებს ამგვარ „ქუქი“ ფაილებს ჩვენს აპლიკაციაში თქვენი ვიზიტების შესახებ, მასალა რაც თქვენ იხილეთ, ლინკები, რომლებსაც მიყევით და ინფორმაცია თქვენი მოწყობილობის და IP მისამართის შესახებ. ზოგჯერ, ჩვენი კომპანია აღნიშნულ ინფორმაციას, სარეკლამო მიზნებისთვის, უზიარებს მესამე მხარეებს.
        </li>
      </ul>
      <br/>
      <b>როგორ შეგიძლიათ მართოთ „ქუქი“ ფაილები</b>
      <br/>
      თქვენ შეგიძლიათ მომართოთ აპლიკაცია ისე, რომ მან არ გამოიყენოს „ქუქი“ ფაილები, მოცემული აპლიკაცია კი გეტყვით, როგორ წაშალოთ ქუქი ფაილები. თუმცა, გაითვალისწინეთ ისიც, რომ შესაძლოა, ასეთ შეთხვევაში ჩვენი აპლიკაციის  გარკვეული ფუნქციები სრულყოფილად ვერ გამოიყენოთ.

      <br/>
      <br/>
      <b>სხვა ვებ გვერდების კონფიდენციალურობის პოლიტიკა</b>
      <br/>
      ჩვენი აპლიკაცია შესაძლოა შეიცავდეს სხვა ვებ გვერდებზე გადასასვლელ ლინკებს. ჩვენი კონფიდენციალურობის პოლიტიკა მხოლოდ მოქმედებს ჩვენს აპლიკაციაზე, შესაბამისად თუ დაკლიკავთ სხვა ვებ გვერდის ლინკზე, აუცილებლად გაეცანით მათ კონფიდენციალურობის პოლიტიკას.
      <br/>
      <br/>

      <b>ცვლილებები ჩვენს კონფიდენციალურობის პოლიტიკაში</b>
      <br/>
      ჩვენი კომპანია მუდმივად შეუსაბამებს კონფიდენციალურობის პოლიტიკას მოქმედ კანონმდებლობასა და პრინციპებს, შესაბამისი განახლებები კი აისახება აპლიკაციაში.
      <br/>
      <br/>

      <b>როგორ შეგვეხმიანოთ ჩვენ</b>
      <br/>
      თუ გაქვთ რაიმე შეკითხვა ჩვენი კომპანიის კონფიდენციალურობის პოლიტიკის თაობაზე, ჩვენს მიერ შეგროვებული ინფორმაციის თაობაზე, ან უბრალოდ გსურთ გამოიყენოთ თქვენი უფლებები პერსონალურ მონაცემებთან დაკავშირებით, შეგვეხმიანეთ შემდეგ მისამართებზე:

      <br/>
      ელ. ფოსტა:levaninad@gmail.com
      <br/>
      მის:agmashenebeli avenue 73
      <br/>
      <br/>

      <b>როგორ შეეხმიანოთ უფლებამოსილ ორგანოებს</b>
      <br/>
      სახელმწიფო ინსპექტორის სამსახური არის დამოუკიდებელი სახელმწიფო ორგანო, რომლის მიზანია, განახორციელოს  პერსონალურ მონაცემთა დამუშავების კანონიერების კონტროლი. შესაბამისად, თუ მიგაჩნიათ, რომ თქვენი პერსონალური მონაცემების დამუშავებისას დარღვეული იქნა თქვენი უფლებები, მიმართეთ სახელმწიფო  ინსპექტორის სამსახურს:
      <br/>
      ტელ: (+995 32) 242 1000
      <br/>
      ელ. ფოსტა: office@stateinspector.ge
      <br/>
      მის: საქართველო, თბილისი, ნ. ვაჩნაძის N°7, 0105 - პერსონალურ მონაცემთა დაცვის მიმართულება;

    </div>
  </div>

</template>

<script>

export default {
  name: "PrivacyPolicy",
  computed: {
    lang() {
      return this.$i18n.locale
    }
  }
}
</script>

<style scoped lang="scss">

</style>
